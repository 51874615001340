import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CloseFormService {
    urlReturn;

    constructor(private router: Router) {}

    saveURL() {
        this.urlReturn = this.router.url;
    }

    closeForm(id, company?) {
        if (this.urlReturn) {
            if (this.urlReturn.startsWith('/ins')) {
                this.urlReturn = `/patient/${
                    this.urlReturn.slice(5).split('/')[1]
                }`;
            }
            return this.router.navigate([this.urlReturn]);
        }
        if (!id) {
            if (company) {
                return this.router.navigate([company]);
            }
            return this.router.navigate(['patients']);
        }

        return this.router.navigate(['patient', id]);
    }
}
