import { Injectable } from '@angular/core';
import { ErrorFormService } from '@services/form/error-form.service';
import { environment } from '@environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CreateCompanyService {
    company;
    specialist;

    constructor(
        private errorService: ErrorFormService,
        private http: HttpClient,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
    ) {}

    initCompanyCreation() {
        this.errorService.resetErrors();
        this.company = {
            address: {},
            disease: 'cardio',
        };

        return this.company;
    }

    initDisease() {
        this.specialist = {
            currentTab: '',
        };
        return this.specialist;
    }

    createCompany(isCardio, isNephro) {
        return this.http
            .post<any>(
                `${environment.apiUrl}/company`,
                { company: this.company },
                {
                    params: { isCardio, isNephro },
                },
            )
            .pipe(
                tap(() => {
                    this.toastr.success(
                        this.translate.instant(
                            'create-company.identification.success',
                        ),
                    );
                    this.router.navigate(['company/all']);
                }),
                catchError((err) => {
                    this.errorService.initErrors(err?.error?.errors);
                    return throwError(() => err);
                }),
            );
    }

    checkIdentification(company, disease) {
        return (
            company.companyName &&
            company.companyType &&
            company.address.completeAddress &&
            company.region &&
            company[disease]?.serviceName &&
            company[disease]?.phones?.length
        );
    }
}
