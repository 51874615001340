import { debounceTime, filter } from 'rxjs/operators';
import { fromEvent, Observable, tap } from 'rxjs';
import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SidebarService } from '@ui/sidebar';
import { LoaderService } from '@ui/loader';
import { AuthService } from '@services/security';
import { DatepickerLanguageService } from '@services/datepicker-language';
import { ScreenService } from '@utils/screen';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    currentUser;
    ROUTES_REGEX_FOR_HIDDEN_SIDEBAR = [/.*patient\/.*\/biologicData.*/];
    ROUTES_FOR_HIDDEN_SIDEBAR = [
        '/edition',
        '/reinclusion',
        '/inclusion-nephro',
        '/edition-nephro',
        '/ins',
        '/create-company',
    ];
    @HostListener('window:keyup', ['$event'])
    keyboardEvent() {
        this.authService.setLastKeyDownTime();
    }

    public get enabled$(): Observable<boolean> {
        return this.loaderService.enabled$;
    }

    constructor(
        private readonly translate: TranslateService,
        private readonly router: Router,
        public authService: AuthService,
        private readonly sidebarService: SidebarService,
        private readonly loaderService: LoaderService,
        private readonly datepickerLanguageService: DatepickerLanguageService,
        private readonly screenService: ScreenService,
    ) {}

    ngOnInit(): void {
        this.deactivateServiceWorker();
        this.initLocale();
        this.initCurrentUser();
        this.onRouteChange();
        this.onResize();
    }

    private onRouteChange(): void {
        this.router.events
            .pipe(
                filter((event) => {
                    return event instanceof NavigationEnd;
                }),
                tap((event: NavigationEnd) => {
                    const needHide = this.checkRoutesForSidebarDisplaying(
                        event.url,
                    );

                    if (needHide) {
                        this.sidebarService.hide();
                    } else {
                        if (!this.sidebarService.previousState) {
                            this.sidebarService.clearState();
                            this.sidebarService.previousState =
                                this.sidebarService.getInitialState();
                        }

                        this.sidebarService.show();
                    }
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    private initCurrentUser(): void {
        this.authService.currentUser
            .pipe(
                tap((user) => {
                    this.currentUser = user;
                    this.translate.use(user.language);
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    private initLocale(): void {
        this.translate.setDefaultLang('fr');
        this.datepickerLanguageService.setDatePickerLanguage();
    }

    private checkRoutesForSidebarDisplaying(url: string): boolean {
        return (
            this.ROUTES_FOR_HIDDEN_SIDEBAR.some((urlConstraint) =>
                url.startsWith(urlConstraint),
            ) ||
            this.ROUTES_REGEX_FOR_HIDDEN_SIDEBAR.some((regex) => regex.test(url))
        );
    }

    private onResize(): void {
        this.screenService.width = window.innerWidth;

        fromEvent(window, 'resize')
            .pipe(
                debounceTime(300),
                tap((event: UIEvent) => {
                    this.screenService.width = (
                        event.target as Window
                    ).innerWidth;
                }),
                untilDestroyed(this),
            )
            .subscribe();
    }

    deactivateServiceWorker() {
        if (navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                registrations.forEach((registration) => {
                    registration.unregister();
                });
            });
        }
    }
}
