import { RouteCategory } from './interfaces/route.interface';

export const kSidebarRoutes = [
    {
        title: RouteCategory.ALERTS,
        links: [
            {
                link: '/alerts-stats',
                icon: 'alert-stats',
                label: 'alertsstats',
            },
            {
                link: '/alerts-manager/created',
                icon: 'alert-manager',
                label: 'alertsmanager',
            },
            { link: '/alerts', icon: 'alerts', label: 'alerts' },
            {
                link: '/alerts-technical',
                icon: 'alert-technical',
                label: 'alertstechnical',
            },
        ],
    },
    {
        title: RouteCategory.USER,
        links: [
            { link: '/patients', icon: 'patients', label: 'patients' },
            {
                link: '/admin-follow',
                icon: 'follow-admin',
                label: 'adminfollow',
            },
            { link: '/carestaff', icon: 'carestaff', label: 'carestaff' },
            {
                link: '/companies',
                icon: 'hospitals',
                label: 'companies',
            },
            { link: '/users', icon: 'user', label: 'users' },
        ],
    },
    {
        title: RouteCategory.OTHER,
        links: [
            { link: '/billing', icon: 'billing', label: 'billing' },
            { link: '/config', icon: 'config', label: 'config' },
            { link: '/device', icon: 'device', label: 'device' },
        ],
    },
];
