import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArrowUpComponent } from '@ui/icon/components/arrow-up/arrow-up.component';
import { ArrowDownComponent } from '@ui/icon/components/arrow-down/arrow-down.component';
import { ManComponent } from '@ui/icon/components/man/man.component';
import { FileSignatureComponent } from '@ui/icon/components/file-signature/file-signature.component';
import { StethoscopeComponent } from '@ui/icon/components/stethoscope/stethoscope.component';
import { AddressCardComponent } from '@ui/icon/components/address-card/address-card.component';
import { PhoneComponent } from '@ui/icon/components/phone/phone.component';
import { MessageComponent } from '@ui/icon/components/message/message.component';
import { HospitalsComponent } from '@ui/icon/components/hospitals/hospitals.component';
import { DoneComponent } from './components/done/done.component';
import { CardioComponent } from './components/cardio/cardio.component';
import { NephroComponent } from './components/nephro/nephro.component';
import { SearchComponent } from './components/search/search.component';
import { ArrowLeftComponent } from './components/arrow-left/arrow-left.component';
import { ArrowRightComponent } from './components/arrow-right/arrow-right.component';
import { MobileKebabMenuComponent } from './components/mobile-kebab-menu/mobile-kebab-menu.component';
import { KebabMenuComponent } from './components/kebab-menu/kebab-menu.component';
import { LogoComponent } from './components/logo/logo.component';
import { HomeComponent } from './components/home/home.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SmsComponent } from './components/sms/sms.component';
import { InfoComponent } from './components/info/info.component';
import { CloseComponent } from './components/close/close.component';
import { DocumentComponent } from './components/document/document.component';
import { UploadComponent } from './components/upload/upload.component';
import { TrashComponent } from './components/trash/trash.component';
import { IconComponent } from './icon.component';
import { IconDirective } from './directives/icon-host.directive';
import { BillingComponent } from './components/billing/billing.component';
import { AlertManagerComponent } from './components/alert-manager/alert-manager.component';
import { AlertStatsComponent } from './components/alert-stats/alert-stats.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { DeviceComponent } from './components/device/device.component';
import { AlertTechnicalComponent } from './components/alert-technical/alert-technical.component';
import { PatientsComponent } from './components/patients/patients.component';
import { FollowAdminComponent } from './components/follow-admin/follow-admin.component';
import { CarestaffComponent } from './components/carestaff/carestaff.component';
import { ConfigComponent } from './components/config/config.component';
import { UserComponent } from './components/user/user.component';
import { ToBillComponent } from './components/to-bill/to-bill.component';
import { NewComponent } from './components/new/new.component';
import { AwaitingTrainingComponent } from './components/awaiting-training/awaiting-training.component';
import { IncludedComponent } from './components/included/included.component';
import { RenewComponent } from './components/renew/renew.component';
import { FirstMeasurementToProcessComponent } from './components/first-measurement-to-process/first-measurement-to-process.component';
import { TreatedComponent } from './components/treated/treated.component';
import { ActiveComponent } from './components/active/active.component';
import { AdminsValidationComponent } from './components/admins-validation/admins-validation.component';
import { ToRenewComponent } from './components/to-renew/to-renew.component';
import { ToCompleteComponent } from './components/to-complete/to-complete.component';
import { AlertsManagementComponent } from './components/alerts-management/alerts-management.component';
import { KebabMenuVerticalBlueComponent } from './components/kebab-menu-vertical-blue/kebab-menu-vertical-blue.component';
import { PictoColibriComponent } from './components/picto-colibri/picto-colibri.component';
import { CachedComponent } from './components/cached/cached.component';
import { GrayUserComponent } from './components/gray-user/gray-user.component';
import { QualifiedUserComponent } from './components/qualified-user/qualified-user.component';
import { WarningComponent } from './components/warning/warning.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        DoneComponent,
        CardioComponent,
        NephroComponent,
        SearchComponent,
        ArrowLeftComponent,
        ArrowRightComponent,
        ArrowUpComponent,
        ArrowDownComponent,
        MobileKebabMenuComponent,
        KebabMenuComponent,
        LogoComponent,
        HomeComponent,
        LogoutComponent,
        ProfileComponent,
        SmsComponent,
        InfoComponent,
        CloseComponent,
        DocumentComponent,
        UploadComponent,
        TrashComponent,
        BillingComponent,
        AlertManagerComponent,
        AlertStatsComponent,
        AlertsComponent,
        DeviceComponent,
        AlertTechnicalComponent,
        PatientsComponent,
        FollowAdminComponent,
        CarestaffComponent,
        ConfigComponent,
        UserComponent,
        IconDirective,
        ToBillComponent,
        NewComponent,
        RenewComponent,
        AwaitingTrainingComponent,
        IncludedComponent,
        FirstMeasurementToProcessComponent,
        TreatedComponent,
        ActiveComponent,
        AdminsValidationComponent,
        IconComponent,
        ToRenewComponent,
        ToCompleteComponent,
        AlertsManagementComponent,
        KebabMenuVerticalBlueComponent,
        PictoColibriComponent,
        CachedComponent,
        GrayUserComponent,
        QualifiedUserComponent,
        WarningComponent,
        ManComponent,
        FileSignatureComponent,
        StethoscopeComponent,
        AddressCardComponent,
        MessageComponent,
        PhoneComponent,
        HospitalsComponent,
    ],
    exports: [
        DoneComponent,
        CardioComponent,
        NephroComponent,
        SearchComponent,
        ArrowLeftComponent,
        ArrowRightComponent,
        ArrowUpComponent,
        ArrowDownComponent,
        CloseComponent,
        MobileKebabMenuComponent,
        KebabMenuComponent,
        LogoComponent,
        HomeComponent,
        LogoutComponent,
        ProfileComponent,
        SmsComponent,
        InfoComponent,
        CloseComponent,
        DocumentComponent,
        UploadComponent,
        TrashComponent,
        BillingComponent,
        AlertManagerComponent,
        AlertStatsComponent,
        AlertsComponent,
        DeviceComponent,
        AlertTechnicalComponent,
        PatientsComponent,
        FollowAdminComponent,
        CarestaffComponent,
        ConfigComponent,
        UserComponent,
        IconDirective,
        ToBillComponent,
        NewComponent,
        RenewComponent,
        AwaitingTrainingComponent,
        IncludedComponent,
        FirstMeasurementToProcessComponent,
        TreatedComponent,
        ActiveComponent,
        AdminsValidationComponent,
        IconComponent,
        ToRenewComponent,
        ToCompleteComponent,
        AlertsManagementComponent,
        KebabMenuVerticalBlueComponent,
        PictoColibriComponent,
        CachedComponent,
        GrayUserComponent,
        QualifiedUserComponent,
        WarningComponent,
        ManComponent,
        FileSignatureComponent,
        StethoscopeComponent,
        AddressCardComponent,
        MessageComponent,
        PhoneComponent,
        HospitalsComponent,
    ],
})
export class IconModule {}
